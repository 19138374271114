import { useEffect, useRef } from 'react';

export const Logo = ({ size = 24, color = 'currentColor', className = '', ...props }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 785 526"
        fill={color}
        className={className}
        style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
        }}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    ><g transform="matrix(0.109938,0,0,-0.109938,-38.4785,589.045)">
            <path

                d="M3720,5349C3390,5318 3102,5237 2819,5095C2201,4786 1732,4220 1541,3555C1502,3419 1464,3215 1455,3095C1452,3046 1446,2993 1444,2978L1438,2950L1670,2950L1670,3003C1670,3078 1704,3298 1734,3416C1816,3741 1977,4060 2185,4310C2497,4686 2911,4948 3377,5065C3587,5118 3736,5133 3989,5127C4289,5120 4484,5082 4755,4975C5391,4727 5884,4190 6090,3525C6135,3378 6160,3245 6185,3008L6191,2950L6412,2950L6406,3028C6374,3393 6294,3686 6144,3985C5860,4548 5375,4984 4789,5203C4462,5326 4057,5381 3720,5349Z" />
            <path d="M3720,4959C2853,4870 2128,4247 1910,3403C1879,3285 1855,3143 1846,3023L1840,2950L2387,2950L2393,3033C2401,3139 2430,3272 2470,3385C2648,3894 3078,4272 3611,4392C3702,4412 3741,4415 3925,4415C4154,4415 4218,4406 4400,4348C4914,4184 5314,3744 5434,3210C5442,3174 5454,3101 5461,3048L5473,2950L6013,2950L6006,3041C5946,3834 5418,4543 4673,4830C4377,4944 4032,4991 3720,4959Z" />
            <path d="M5895,2780C5828,2770 5721,2720 5657,2670C5566,2598 5513,2522 5456,2381L5430,2317L5430,603L5537,597C5595,593 5716,590 5805,590L5967,590L5973,1338C5976,1749 5982,2097 5986,2112C5995,2151 6034,2186 6083,2200C6112,2208 6289,2213 6645,2217C6944,2220 7173,2226 7183,2232C7227,2255 7493,2714 7488,2758L7485,2785L6715,2786C6292,2787 5923,2784 5895,2780Z" />
            <path d="M350,2768C350,2754 380,2700 548,2418C606,2320 661,2238 669,2234C678,2231 953,2223 1280,2218C1607,2212 1877,2205 1880,2203C1883,2200 1888,1883 1891,1497C1895,1111 1898,747 1899,688L1900,580L2164,580C2395,580 2429,582 2434,596C2444,623 2440,2219 2430,2295C2403,2486 2251,2669 2053,2748L1985,2775L1168,2778C489,2781 350,2779 350,2768Z" />
            <path d="M3235,2769C3115,2758 2982,2732 2932,2710C2867,2681 2750,2559 2712,2480C2647,2347 2619,2182 2620,1935C2620,1595 2677,1410 2822,1275C2937,1168 2989,1156 3378,1145L3620,1137L3620,1046C3620,996 3623,871 3627,768L3633,580L3914,580C4069,580 4201,584 4209,589C4231,603 4234,795 4220,1246C4213,1472 4210,1666 4214,1676C4220,1690 4243,1652 4314,1515C4447,1256 4490,1181 4514,1165C4529,1155 4593,1149 4745,1144L4955,1137L4998,1184C5081,1274 5165,1425 5206,1559C5251,1706 5266,2049 5235,2240C5190,2514 5081,2661 4857,2749L4779,2780L4047,2779C3645,2778 3279,2774 3235,2769ZM3620,2190L3620,1728L3458,1732C3358,1734 3287,1741 3273,1748C3261,1755 3239,1785 3224,1817C3171,1924 3191,2082 3264,2150C3296,2179 3344,2186 3513,2189L3620,2190ZM4614,2145C4657,2088 4674,2019 4668,1926C4662,1834 4637,1781 4588,1757C4567,1747 4499,1733 4423,1725C4353,1717 4280,1708 4263,1706L4230,1701L4230,2190L4579,2190L4614,2145Z" />
        </g>
    </svg>
)

export  const AnimatedLogo = () => {
  const stop1Ref = useRef<SVGStopElement>(null);
  const stop2Ref = useRef<SVGStopElement>(null);
  const stop3Ref = useRef<SVGStopElement>(null);
  const stop4Ref = useRef<SVGStopElement>(null);

  useEffect(() => {
    let offset1 = -110;
    let offset2 = -100;
    let offset3 = -30;
    let offset4 = -20;
    const direction = 3;

    const resetOffsets = () => {
      offset1 = -110;
      offset2 = -100;
      offset3 = -30;
      offset4 = -20;
    };

    const updateOffsets = () => {
      offset1 += direction;
      offset2 += direction;
      offset3 += direction;
      offset4 += direction;

      if (offset2 >= 150 || offset2 <= -100) {
        resetOffsets();
        setTimeout(updateOffsets, 400);
      } else {
        requestAnimationFrame(updateOffsets);
      }

      stop1Ref.current?.setAttribute('offset', `${offset1}%`);
      stop2Ref.current?.setAttribute('offset', `${offset2}%`);
      stop3Ref.current?.setAttribute('offset', `${offset3}%`);
      stop4Ref.current?.setAttribute('offset', `${offset4}%`);
    };

    updateOffsets();
  }, []);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-[60%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <svg
          id="logo"
          className="w-[94%] h-auto"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
          }}
          viewBox="0 0 1000 1000"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
        >
          <defs>
            <linearGradient id="fill-gradient">
              <stop ref={stop1Ref} offset="0%" style={{ stopColor: 'white', stopOpacity: 0.0 }} />
              <stop ref={stop2Ref} offset="30%" style={{ stopColor: 'white', stopOpacity: 1.0 }} />
              <stop ref={stop3Ref} offset="70%" style={{ stopColor: 'white', stopOpacity: 1.0 }} />
              <stop ref={stop4Ref} offset="100%" style={{ stopColor: 'white', stopOpacity: 0.0 }} />
            </linearGradient>
          </defs>
          <g id="Artboard1" transform="matrix(1.27389,0,0,1.90114,0,0)">
            <g transform="matrix(0.0863013,0,0,-0.0578274,54.1819,434.5)">
              <path
                id='arc-upper'
                style={{
                  stroke: 'rgba(255,255,255,1.0)',
                  strokeWidth: 32,
                  fill: 'url(#fill-gradient)',
                  fillRule: 'nonzero',
                }}
                d="M3720,5349C3390,5318 3102,5237 2819,5095C2201,4786 1732,4220 1541,3555C1502,3419 1464,3215 1455,3095C1452,3046 1446,2993 1444,2978L1438,2950L1670,2950L1670,3003C1670,3078 1704,3298 1734,3416C1816,3741 1977,4060 2185,4310C2497,4686 2911,4948 3377,5065C3587,5118 3736,5133 3989,5127C4289,5120 4484,5082 4755,4975C5391,4727 5884,4190 6090,3525C6135,3378 6160,3245 6185,3008L6191,2950L6412,2950L6406,3028C6374,3393 6294,3686 6144,3985C5860,4548 5375,4984 4789,5203C4462,5326 4057,5381 3720,5349Z"
              />
              <path
                id='arc-lower'
                style={{ fill: 'rgba(255,255,255,1.0)', fillRule: 'nonzero' }}
                d="M3720,4959C2853,4870 2128,4247 1910,3403C1879,3285 1855,3143 1846,3023L1840,2950L2387,2950L2393,3033C2401,3139 2430,3272 2470,3385C2648,3894 3078,4272 3611,4392C3702,4412 3741,4415 3925,4415C4154,4415 4218,4406 4400,4348C4914,4184 5314,3744 5434,3210C5442,3174 5454,3101 5461,3048L5473,2950L6013,2950L6006,3041C5946,3834 5418,4543 4673,4830C4377,4944 4032,4991 3720,4959Z"
              />
              <path
                style={{ fill: 'rgba(255,255,255,1.0)', fillRule: 'nonzero' }}
                d="M5895,2780C5828,2770 5721,2720 5657,2670C5566,2598 5513,2522 5456,2381L5430,2317L5430,603L5537,597C5595,593 5716,590 5805,590L5967,590L5973,1338C5976,1749 5982,2097 5986,2112C5995,2151 6034,2186 6083,2200C6112,2208 6289,2213 6645,2217C6944,2220 7173,2226 7183,2232C7227,2255 7493,2714 7488,2758L7485,2785L6715,2786C6292,2787 5923,2784 5895,2780Z"
              />
              <path
                style={{ fill: 'rgba(255,255,255, 1.0)', fillRule: 'nonzero' }}
                d="M350,2768C350,2754 380,2700 548,2418C606,2320 661,2238 669,2234C678,2231 953,2223 1280,2218C1607,2212 1877,2205 1880,2203C1883,2200 1888,1883 1891,1497C1895,1111 1898,747 1899,688L1900,580L2164,580C2395,580 2429,582 2434,596C2444,623 2440,2219 2430,2295C2403,2486 2251,2669 2053,2748L1985,2775L1168,2778C489,2781 350,2779 350,2768Z"
              />
              <path
                style={{ fill: 'rgba(255,255,255, 1.0)', fillRule: 'nonzero' }}
                d="M3235,2769C3115,2758 2982,2732 2932,2710C2867,2681 2750,2559 2712,2480C2647,2347 2619,2182 2620,1935C2620,1595 2677,1410 2822,1275C2937,1168 2989,1156 3378,1145L3620,1137L3620,1046C3620,996 3623,871 3627,768L3633,580L3914,580C4069,580 4201,584 4209,589C4231,603 4234,795 4220,1246C4213,1472 4210,1666 4214,1676C4220,1690 4243,1652 4314,1515C4447,1256 4490,1181 4514,1165C4529,1155 4593,1149 4745,1144L4955,1137L4998,1184C5081,1274 5165,1425 5206,1559C5251,1706 5266,2049 5235,2240C5190,2514 5081,2661 4857,2749L4779,2780L4047,2779C3645,2778 3279,2774 3235,2769ZM3620,2190L3620,1728L3458,1732C3358,1734 3287,1741 3273,1748C3261,1755 3239,1785 3224,1817C3171,1924 3191,2082 3264,2150C3296,2179 3344,2186 3513,2189L3620,2190ZM4614,2145C4657,2088 4674,2019 4668,1926C4662,1834 4637,1781 4588,1757C4567,1747 4499,1733 4423,1725C4353,1717 4280,1708 4263,1706L4230,1701L4230,2190L4579,2190L4614,2145Z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};
